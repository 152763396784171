import React from 'react'

function OurProjects() {
  return (
    <div>
      <>
        <h2>Our Projects</h2>
      </>
    </div>
  )
}

export default OurProjects;
