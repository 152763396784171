import React from 'react'

function Testimonials() {
  return (
    <div>
      <><h2>Testimonials</h2></>
    </div>
  )
}

export default Testimonials;
