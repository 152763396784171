import React from 'react'

function AboutUs() {
  return (
    <div>
      <>
        <h2>About Us</h2>
      </>
    </div>
  )
}

export default AboutUs;
