import React, { useState } from 'react';
import Home from './Home';
import AboutUs from './AboutUs';
import ITIndustries from './ITIndustries';
import Testimonials from './Testimonials';
import OurTeam from './OurTeam';
import Careers from './Careers';
import OurProjects from './OurProjects';
import Blog from './Blog';
import ContactUs from './ContactUs';
import './index.css';
import './ResponsiveMenu.css';
import Header from './Header';

function App() {
  const [page, setPage] = useState('Home');

  const renderPage = () => {
    switch (page) {
      case 'Header':return<Header />
      case 'Home': return <Home />;
      case 'AboutUs': return <AboutUs />;
      case 'ITIndustries': return <ITIndustries />;
      case 'Testimonials': return <Testimonials />;
      case 'OurTeam': return <OurTeam />;
      case 'Careers': return <Careers />;
      case 'OurProjects': return <OurProjects />;
      case 'Blog': return <Blog />;
      case 'ContactUs': return <ContactUs />;
      default: return <Home />;
    }
  };

  return (
    <div className="App">
      <Header setPage={setPage} />
      <div className="content">
        {renderPage()}
      </div>
    </div>
  );
}

export default App;
