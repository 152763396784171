import React from 'react'

function Blog() {
  return (
    <div>
      <><h2>Blog</h2></>
    </div>
  )
}

export default Blog;
