import React from 'react'

function Careers() {
  return (
    <div>
      <><h2>Careers</h2></>
    </div>
  )
}

export default Careers;
