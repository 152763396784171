import React from 'react'

function ContactUs() {
  return (
    <div>
      <><h2>Contact Us</h2></>
    </div>
  )
}

export default ContactUs;
