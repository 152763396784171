import React from 'react'

function OurTeam() {
  return (
    <div>
      <>
      <h2>Our Team</h2>
      </>
    </div>
  )
}

export default OurTeam;
