import React, { useState } from "react";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Header({ setPage }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleAboutDropdown = () =>
    setIsAboutDropdownOpen(!isAboutDropdownOpen);

  return (
    <header className="header">
      {/* Mobile Navbar */}
      <nav className="navbar navbar-light bg-light d-lg-none">
        <div className="container-fluid wrapper">
          {/* Brand Logo */}
          <div className="wrapper">
            <div className="navbar-brand" onClick={() => setPage("Home")}>
              <img
                src="./assets/images/dpt_logo.png"
                alt="MyWebsite Logo"
                className="logo-img"
              />
            </div>
          </div>
          {/* Toggle Button for Mobile */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="sidebarMenu"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      {/* Sidebar Menu */}
      <div
        className={`sidebar-menu ${isMenuOpen ? "open" : ""}`}
        id="sidebarMenu"
      >
        <div className="menu-close-btn" onClick={toggleMenu}>
          ×
        </div>
        <ul className="navbar-nav">
          {/* Home Link */}
          <li className="nav-item">
            <div
              className="nav-link animated-item"
              onClick={() => {
                setPage("Home");
                toggleMenu();
              }}
            >
              Home
            </div>
          </li>

          {/* Dropdown Menu */}
          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle animated-item"
              id="navbarDropdown"
              role="button"
              onClick={toggleAboutDropdown}
              aria-expanded={isAboutDropdownOpen}
            >
              About Us
            </div>
            {isAboutDropdownOpen && (
              <ul
                className="dropdown-menu show animated-dropdown"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setPage("ITIndustries");
                      toggleMenu();
                    }}
                  >
                    IT Industries
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setPage("Testimonials");
                      toggleMenu();
                    }}
                  >
                    Testimonials
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setPage("OurTeam");
                      toggleMenu();
                    }}
                  >
                    Our Team
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setPage("Careers");
                      toggleMenu();
                    }}
                  >
                    Careers
                  </div>
                </li>
              </ul>
            )}
          </li>

          {/* Other Links */}
          <li className="nav-item">
            <div
              className="nav-link animated-item"
              onClick={() => {
                setPage("OurProjects");
                toggleMenu();
              }}
            >
              Our Projects
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link animated-item"
              onClick={() => {
                setPage("Blog");
                toggleMenu();
              }}
            >
              Blog
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link animated-item"
              onClick={() => {
                setPage("ContactUs");
                toggleMenu();
              }}
            >
              Contact Us
            </div>
          </li>
        </ul>
      </div>

      {/* Overlay for Sidebar */}
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}

      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-block">
        <div className="container-fluid wrapper">
          {/* Brand Logo */}
          <div className="navbar-brand" onClick={() => setPage("Home")}>
            <img
              src="./assets/images/dpt_logo.png"
              alt="MyWebsite Logo"
              className="logo-img"
            />
          </div>

          {/* Horizontal Menu */}
          <div className="navbar-nav ml-auto">
            <div className="nav-item">
              <div className="nav-link" onClick={() => setPage("Home")}>
                Home
              </div>
            </div>

            <div className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                onClick={toggleAboutDropdown}
                aria-expanded={isAboutDropdownOpen}
              >
                About Us
              </div>
              {isAboutDropdownOpen && (
                <ul
                  className="dropdown-menu show"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <div
                      className="dropdown-item drp_menu"
                      onClick={() => setPage("ITIndustries")}
                    >
                      IT Industries
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item drp_menu"
                      onClick={() => setPage("Testimonials")}
                    >
                      Testimonials
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item drp_menu"
                      onClick={() => setPage("OurTeam")}
                    >
                      Our Team
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item drp_menu"
                      onClick={() => setPage("Careers")}
                    >
                      Careers
                    </div>
                  </li>
                </ul>
              )}
            </div>

            <div className="nav-item">
              <div className="nav-link" onClick={() => setPage("OurProjects")}>
                Our Projects
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link" onClick={() => setPage("Blog")}>
                Blog
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link" onClick={() => setPage("ContactUs")}>
                Contact Us
              </div>
            </div>
          </div>
          <div className="social-media-icons">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://wa.me/yourphonenumber"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
